import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Input, notification } from "antd";

export default function AddUser(props) {
  const { setLoading, title, client } = props;

  useEffect(() => {
    console.log("yyyyyyyyyyyyyyyy", client);
  });

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const buttonayout = {
    wrapperCol: {
      xs: { span: 12, offset: 6 },
      sm: { span: 16, offset: 8 },
    },
  };

  const JoinToAClient = (values) => {
    const email = values.email;
    const username = values.username;
    const clientString = values.clientString;

    setLoading(true);
    axios
      // https://apitranscribe.ranuvijay.me/users/createUser
      .post("https://apitranscribe.ranuvijay.me/users/createUser", {
        // .post("https://apitranscribe.ranuvijay.me/dj-rest-auth/registration/", {
        email: email,
        username: username,
        client: clientString,
      })
      .then((res) => {
        notification["success"]({
          message: "Success !!",
          description:
            "New User created and joined a Client, Password is Pass@123",
          //   <b>Pass@123</b>
          // )}`,
          duration: 0,
        });
        setLoading(false);
        // alert("done");
      })
      .catch((err) => {
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong.",
        });

        setLoading(false);

        // alert(JSON.stringify(err));
        // dispatch(authFail(err));
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // width:"100%",
        // backgroundColor: "red",
      }}
    >
      <h1>{title}</h1>
      {/* <br /> */}

      <Form
        onFinish={JoinToAClient}
        // onFinishFailed={onFinishFailed}
        {...formItemLayout}
        // layout='horizontal'
        initialValues={{ clientString: client ? client : null }}
        style={{
          padding: "20px 40px",
          border: "1px solid #d9d9d9",
        }}
        labelAlign="left"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Input E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Input Mobile Code",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Client String"
          name="clientString"
          rules={[
            {
              required: true,
              message: "Input Client String",
            },
          ]}
        >
          <Input disabled={client ? true : false} />
        </Form.Item>

        <Form.Item
          {...buttonayout}
          // wrapperCol={{
          //   // offset: 8,
          //   span: 16,
          // }}
        >
          <Button type="primary" htmlType="submit" shape="round">
            {title}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
