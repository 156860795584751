class Websocketservice {
  static instance = null;

  callback = {};

  static getInstance() {
    if (!Websocketservice.instance) {
      Websocketservice.instance = new Websocketservice();
    }
    return Websocketservice.instance;
  }

  constructor() {
    this.socketRef = null;
  }

  connect(chatGroup, token) {
    console.log(chatGroup);
    console.log(token);
    // const path = `ws://localhost:8000/ws/chat/${chatGroup}/?token=${token}`;
    // const path = `ws://64.227.152.27:8001/ws/chat/${chatGroup}/?token=${token}`;
    const path = `wss://apitranscribe.ranuvijay.me/ws/chat/${chatGroup}/?token=${token}`;
    if (chatGroup) {
      this.socketRef = new WebSocket(path);
    }

    this.socketRef.onopen = () => {
      console.log("websocket open");
    };

    // this.socketNewMessage(
    //   JSON.stringify({
    //     command: "fetch_messages",
    //   })
    // );
    this.socketRef.onmessage = (e) => {
      this.socketNewMessage(e.data);
      // console.log("sending a message");
    };

    this.socketRef.onerror = (e) => {
      console.log(e.message);
    };

    this.socketRef.onclose = () => {
      console.log("websocket close");
      this.connect(chatGroup, token);
    };
  }
  socketNewMessage(data) {
    // if (data) alert(data[command]);
    // console.log(data);
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    if (Object.keys(this.callback).length === 0) {
      return;
    }
    if (command === "messages") {
      // console.log(data);
      // console.log(this.callback[command]);
      this.callback[command](parsedData.messages);
    }
    if (command === "new_message") {
      // console.log(data);
      // let  d = data.command;
      // console.log(d);
      this.callback[command](parsedData.message);
    }
    if (command === "userChatGroups") {
      this.callback[command](parsedData.chatGRoups);
    }
    if (command === "new_userChatGroups") {
      this.callback[command](parsedData.chatGRoup);
    }
    if (command === "activeSessions") {
      this.callback[command](parsedData.actveSession);
    }
    if (command === "new_activeSessions") {
      this.callback[command](parsedData.actveSession);
    }
  }

  fetchMessages(username, chatGroup) {
    this.sendMessage({
      command: "fetch_messages",
      username: username,
      chatGroup: chatGroup,
    });
  }

  fetchUserChatGroups(user) {
    this.data = {
      command: "userChatGroups",
      userId: user,
    };
    this.sendMessage(JSON.stringify(this.data));
  }

  fetchActiveSession(user) {
    this.data = {
      command: "activeChats",
      userId: user,
    };
    this.sendMessage(JSON.stringify(this.data));
  }

  setCUrrentGroup(current) {
    this.data = {
      command: "currentGroup",
      groupId: current,
    };
    this.sendMessage(JSON.stringify(this.data));
  }

  newChatMessage(message) {
    // console.log("message.content");

    // const audio = new Audio();
    // audio.autoplay = true;
    // audio.srcObject = message.content;

    // console.log("new message to send to server");
    // this.sendMessage({
    //   command: "new_message",
    //   from: message.from,
    //   message: message.content,
    //   chatGroup: message.chatGroup,
    // });

    this.data = {
      command: "new_message",
      from: message.from,
      message: message.content,
      session: message.session,
      participants: message.participants,
    };

    this.sendMessage(JSON.stringify(this.data));
  }

  addCallbacks(
    messagesCallback,
    newMessageCallback,
    userChatGroups,
    newUserChat,
    actveSessions,
    newSession
  ) {
    this.callback["messages"] = messagesCallback;
    this.callback["new_message"] = newMessageCallback;
    this.callback["userChatGroups"] = userChatGroups;
    this.callback["new_userChatGroups"] = newUserChat;
    this.callback["activeSessions"] = actveSessions;
    this.callback["new_activeSessions"] = newSession;
  }

  sendMessage(data) {
    try {
      // console.log(JSON.stringify(data));
      // console.log({...data});
      // console.log(data);
      // console.log("send commands and data");
      // this.socketRef.send(JSON.stringify({ ...data }));

      this.socketRef.send(data);
      // this.socketRef.send(JSON.stringify(data));
    } catch (e) {
      console.log(e.message);
    }
  }

  state() {
    // console.log(this.socketRef);
    return this.socketRef?.readyState;
  }

  disconnect() {
    console.log("disconnect");
    this.socketRef.close();
  }

  // waitForSocketConnection(callback) {
  //   const socket = this.socketRef;
  //   const recursion = this.waitForSocketConnection;
  //   setTimeout(() => {
  //     if (socket.readySTate === 1) {
  //       console.log("connection is secure");
  //       if (callback != null) {
  //         callback();
  //       }
  //       return;
  //     } else {
  //       console.log("waiting for connection");
  //       recursion(callback);
  //     }
  //   }, 1);
  // }
}

const WebSocketInstance = Websocketservice.getInstance();

export default WebSocketInstance;
