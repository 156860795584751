import { useState } from "react";
import axios from "axios";
import AddUser from "../main/addUser";
import PublicMenu from "./publicMenu";

import { Button, Form, Input, Space, notification, Spin } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const buttonayout = {
  wrapperCol: {
    xs: { span: 12, offset: 6 },
    sm: { span: 16, offset: 8 },
  },
};

export default function Register() {
  const [authJoinClient, setAuthJoinClient] = useState({
    email: null,
    clientString: null,
    mobileNumber: null,
    mobileCode: null,
  });

  const [createClient, setCreateClient] = useState(false);

  const [loading, setLoading] = useState(false);

  const changeClientTYpe = () => {
    setCreateClient(!createClient);
  };

  const CreateClientFn = (values) => {
    const email = values.email;
    const clientName = values.clientName;
    const username = values.username;

    setLoading(true);

    axios
      .post("https://apitranscribe.ranuvijay.me/client/", {
        // .post("https://apitranscribe.ranuvijay.me/dj-rest-auth/registration/", {

        // const [authNewClient, setAuthNewClient] = useState({
        //   email: null,
        //   clientName: null,
        //   mobileNumber: null,
        //   mobileCode: null,
        //   mobileNumber
        // });

        clientName: clientName,
        email: email,
        username: username,
      })
      .then((res) => {
        notification["success"]({
          message: "Success !!",
          description: "New Client and User created. Password is Pass@123",
          duration: 0,
        });
        setLoading(false);
        // alert(JSON.stringify("created"));
      })
      .catch((err) => {
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong.",
        });
        setLoading(false);
      });
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Spin />
    </div>
  ) : (
    <>
      <PublicMenu />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "95vh",
          // backgroundColor: "blue",
        }}
      >
        <Space>
          <Button
            onClick={changeClientTYpe}
            type="primary"
            shape="round"
            disabled={!createClient && true}
          >
            Create a new client
          </Button>

          <Button
            onClick={changeClientTYpe}
            type="primary"
            shape="round"
            disabled={createClient && true}
          >
            Join Client
          </Button>
        </Space>
        <br />
        {createClient ? (
          <AddUser setLoading={setLoading} title="Join to a Client" />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // width:"60%",
              // backgroundColor: "red",
            }}
          >
            <h1>Create a new Client</h1>
            {/* <br /> */}

            <Form
              onFinish={CreateClientFn}
              // onFinishFailed={onFinishFailed}
              {...formItemLayout}
              // layout='horizontal'

              style={{
                padding: "30px 50px",
                border: "1px solid #d9d9d9",
                // width:'50%'
              }}
              labelAlign="left"
            >
              <Form.Item
                label="Client Name"
                name="clientName"
                rules={[
                  {
                    required: true,
                    message: "Input Client Name.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Input E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="User Name"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Input Username",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item {...buttonayout}>
                <Button type="primary" htmlType="submit" shape="round">
                  Create a New Client
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </>
  );
}
