import PublicMenu from "./publicMenu";
import { Space, Typography } from "antd";

const { Text } = Typography;

export default function Home() {
  return (
    <>
      <PublicMenu />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <h1>
          <Text strong>Transcriber App</Text>, Download the text file of the
          Audio
        </h1>
        <h2>
          Audio Chat, <Text mark>call</Text> with friends, colleague. Chat with
          individual or create a group{" "}
        </h2>
        {/* <h3>Chat with individual or create a group </h3> */}
        <h3>
          <Text underline>** Use English as a language</Text>{" "}
        </h3>
        {/* <h5></h5> */}

        <p>
          The App is in building stage, but it works for all of its
          functionalities defined, do reload the page if you get an issue
        </p>

        <p>
          <Text code>
            <Text strong> Note -</Text>
          </Text>{" "}
          This is a SAAS based Application, create your own client, add users to
          your client and enjoy the audio call, others won't see your users.{" "}
        </p>
      </div>
    </>
  );
}

// {/* <div className="space-x-4">
//   {/* <Link to="/">
//     <button className="px-10 py-4 rounded bg-blue-300">Home</button>
//   </Link> */}
//   <Link to="/login">
//     <button className="px-10 py-4 rounded bg-blue-300">Login</button>
//   </Link>
//   <Link to="/register">
//     <button className="px-10 py-4 rounded bg-blue-300">Register</button>
//   </Link>
// </div> */}
