import axios from "axios";
import { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import WebSocketInstance from "./websockett";
import AddUser from "./addUser";
import moment from "moment";

import { DownOutlined, MessageTwoTone } from "@ant-design/icons";
import {
  Layout,
  Menu,
  Dropdown,
  Avatar,
  Modal,
  Form,
  Input,
  Select,
  Button,
  Spin,
  List,
  notification,
  DatePicker,
  TimePicker,
} from "antd";

const { Header, Content, Footer, Sider } = Layout;

const { Option } = Select;

function Application() {
  const navigate = useNavigate();

  const [loggedInUserDetail, setLoggedInUserDetail] = useState(null);
  const [groups, setGroups] = useState(null);

  const [chatMembers, setChatMembers] = useState(null);

  const [newCHatGroup, setNewChatGroup] = useState(null);
  const [newSession, setNewSession] = useState(null);

  const [selectedSession, setSelectedSession] = useState(null);

  const [userList, setUserList] = useState(null);

  const [activeSessions, setActiveSessions] = useState(null);

  const [groupMemberInSelectedSession, setGroupMemberInSelectedSession] =
    useState(null);

  const [sessionToGetSessionText, setSessionToGetSessionText] = useState(null);

  const [userToGetSessionText, setUserToGetSessionText] = useState(null);

  const [selectedSessionforTranscribe, setSelectedSessionforTranscribe] =
    useState(null);

  const [showGetTRanscribedText, setShowGetTRanscribedText] = useState(false);
  const [showCReateGroupMOdal, setShowCReateGroupMOdal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showCreateSessionModal, setShowCreateSessionModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showGroupMemberModal, setShowGroupMemberModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [loadingGroupMember, setLoadingGroupMember] = useState(false);
  const [loadingCreateSession, setLoadingCreateSession] = useState(false);

  const [connection, setConnection] = useState(false);

  const [token, setToken] = useState(localStorage.getItem("token"));

  const [mediaSource] = useState(new MediaSource());

  const [queue, setQueue] = useState([]);

  const [b, setB] = useState(null);

  // let blobArray = [];
  // let arrayOfBlobs = [];

  const sourceBuffer = useRef(null);
  const selectedSessionInRef = useRef(null);
  const BASE64_MARKER = ";base64,";

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    // }, [token]);

    // useEffect(() => {
    else {
      axios
        .get("https://apitranscribe.ranuvijay.me/users/userDetail", {
          headers: {
            Authorization: `Token ${token} `,
          },
        })
        .then((d) => {
          console.log(d.data);
          setLoggedInUserDetail(d.data);
        })
        .catch((e) => {
          alert(JSON.stringify(e));
        });
    }
  }, [token]);

  useEffect(() => {
    if (loggedInUserDetail) {
      console.log("hfdgkj", loggedInUserDetail);
      WebSocketInstance.connect(loggedInUserDetail[0].id, token);
    }

    return () => {
      if (connection) WebSocketInstance.disconnect();
    };
  }, [loggedInUserDetail]);

  useEffect(() => {
    if (!connection) {
      const interval = setInterval(() => {
        if (WebSocketInstance.state() !== 0) {
          if (WebSocketInstance.state() === 1) {
            console.log("connection is secure");
            setConnection(true);
            clearInterval(interval);
          }
        } else {
          console.log("waiting for connection");
        }
      }, 10);
    }
  }, [loggedInUserDetail]);

  useEffect(() => {
    if (connection) {
      WebSocketInstance.addCallbacks(
        setMessages,
        addMessage,
        setChatGroups,
        addNewChatGroup,
        setSessions,
        addNewSession
      );
      WebSocketInstance.fetchUserChatGroups(loggedInUserDetail[0].id);
      WebSocketInstance.fetchActiveSession(loggedInUserDetail[0].id);
    }
  }, [connection]);

  const setMessages = () => {};

  useEffect(() => {
    const video = document.getElementById("music");
    var url = URL.createObjectURL(mediaSource);
    video.autoplay = true;

    // 3. Set the video's `src` to the object URL
    video.src = url;

    function aa() {
      // if(binary){

      // video.autoplay = true;

      // console.log(binary);
      // console.log(this.readyState); // open

      // NOTE: Browsers are VERY picky about the codec being EXACTLY
      // right here. Make sure you know which codecs you're using!
      // audio/webm;codecs=opus
      sourceBuffer.current = mediaSource.addSourceBuffer(
        "audio/webm; codecs=opus"
      );
      console.log(sourceBuffer.current);

      // sourceBuffer.addEventListener("updatestart", function (e) {
      //   console.log("updatestart: " + mediaSource.readyState);
      // });
      // sourceBuffer.addEventListener("update", function (e) {
      //   console.log("update: " + mediaSource.readyState);
      // });
      // sourceBuffer.addEventListener("updateend", function (e) {
      //   console.log("updateend: " + mediaSource.readyState);
      // });
      // sourceBuffer.addEventListener("error", function (e) {
      //   console.log("error: " + mediaSource.readyState);
      // });
      // sourceBuffer.addEventListener("abort", function (e) {
      //   console.log("abort: " + mediaSource.readyState);
      // });

      // sourceBuffer.addEventListener("update", function () {
      //   // Note: Have tried 'updateend'
      //   if (queue.length > 0 && !sourceBuffer.updating) {
      //     sourceBuffer.appendBuffer(queue.shift());
      //   }
      // });

      // sourceBuffer.appendBuffer(binary);

      console.log(mediaSource.sourceBuffers);

      // console.log(mediaSource.activeSourceBuffers);

      // sourceBuffer.appendBuffer(a.message);

      // If we requested any video data prior to setting up the SourceBuffer,
      // we want to make sure we only append one blob at a time
      // sourceBuffer.addEventListener("updateend", appendToSourceBuffer);
    }
    // }

    mediaSource.addEventListener("sourceopen", aa);

    // mediaSource.addEventListener("sourceopen", function (e) {
    //   console.log("sourceopen: " + mediaSource.readyState);
    // });

    mediaSource.addEventListener("sourceended", function (e) {
      console.log("sourceended: " + mediaSource.readyState);
    });
    mediaSource.addEventListener("sourceclose", function (e) {
      console.log("sourceclose: " + mediaSource.readyState);
    });
    mediaSource.addEventListener("error", function (e) {
      console.log("error: " + mediaSource.readyState);
    });
  }, []);

  useEffect(() => {
    if (b) {
      // if (selectedSession === a.session) {
      //   console.log(selectedSession);
      //   console.log(a.session);
      //   console.log("hahahahahahahahahahahahahahahahahahahahahah");
      // } else {
      //   console.log(selectedSession);
      //   console.log(a.session);
      // }

      if (sourceBuffer.current.updating || queue.length > 0) {
        setQueue(b);
      } else {
        sourceBuffer.current.appendBuffer(b);
      }
    }
  }, [b]);

  const addMessage = (a) => {
    // console.log(a);

    // console.log(selectedSession);
    // console.log(selectedSessionInRef);

    // if (selectedSessionInRef.current === a.session) {
    let binary = convertURIToBinary(a.message);
    setB(binary);
    // } else {
    //   console.log("revieved new voice message");
    //   // notification["info"]({
    //   //   message: "New Voice Message",
    //   //   description: "SOme",
    //   // });
    // }
  };

  const setChatGroups = useCallback((userChatGroups) => {
    setGroups(userChatGroups);
  }, []);

  const addNewChatGroup = (newCHatGroup) => {
    setNewChatGroup(newCHatGroup);
  };

  useEffect(() => {
    if (newCHatGroup) {
      setGroups([...groups, newCHatGroup]);
      //verify this with above line
      //  setChatGroups([...groups, newCHatGroup]);
      if (!showGroupModal) {
        notification["info"]({
          message: "Info !!",
          description: "Someone added you to a new Group",
        });
      }
    }
  }, [newCHatGroup]);

  const setSessions = (values) => {
    setActiveSessions(values);
  };

  const addNewSession = (newSessn) => {
    setNewSession(newSessn);
  };

  useEffect(() => {
    if (newSession) {
      setActiveSessions([...activeSessions, newSession]);
      console.log("aaaaaa", showGroupModal);
    }
  }, [newSession]);

  function convertURIToBinary(dataURI) {
    let raw = window.atob(dataURI);
    let rawLength = raw.length;
    let arr = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      arr[i] = raw.charCodeAt(i);
    }
    return arr;
  }

  const startAudio = () => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: false,
        })
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream, {
            mimeType: "audio/webm",
          });
          // mediaRecorder.start();

          mediaRecorder.addEventListener("dataavailable", (event) => {
            // console.log("Got blob data:", event.data);
            if (event.data && event.data.size > 0) {
              // webSocket.send(event.data);

              // const video = document.getElementById("music");
              // video.autoplay = true;
              // video.srcObject = event.data;

              let reader = new FileReader();
              reader.onloadend = () => {
                // reader.onloadend = () => {
                // console.log(reader.result);

                console.log(reader.result);
                let base64Index =
                  reader.result.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
                let base64 = reader.result.substring(base64Index);
                // console.log(base64);

                // var data =
                //   "data" +
                //   encodeURIComponent(reader.result.split(";base64,")[1]);

                // const base64EncodedData = reader.result.split(',')[1];

                const messageObject = {
                  from: loggedInUserDetail[0].id,
                  content: base64,
                  // content: reader.result,
                  session: selectedSession,
                  participants: groupMemberInSelectedSession,
                };
                WebSocketInstance.newChatMessage(messageObject);

                // You can upload the base64 to server here.
              };

              reader.readAsDataURL(event.data);
            }
          });

          mediaRecorder.start(1000);

          // const audio = new Audio();
          // audio.autoplay = true;
          // audio.srcObject = stream;

          // const video = document.getElementById("music");
          // video.autoplay = true;
          // video.srcObject = stream;
          // console.log("dg");
          /* use the stream */
        })
        .catch((err) => {
          /* handle the error */
        });
    } else {
      notification["error"]({
        message: "Media is not supported",
        description: "Not supported by browser.",
      });
    }
  };

  const getUserList = () => {
    const userClient = loggedInUserDetail[0].client;
    axios
      .get(
        `https://apitranscribe.ranuvijay.me/users/usr/?client=${userClient}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((d) => {
        if (d.data.length === 1) {
          console.log("no user in the group, create user");
        }
        console.log(d.data);
        const data = d.data;
        const loggedInUser = loggedInUserDetail[0].id;
        console.log("ranu");
        console.log(loggedInUser);
        console.log("vijay");

        const reqData = data.filter((d) => d.id !== loggedInUser);
        console.log(reqData);

        setUserList(reqData);
        // alert(JSON.stringify(d.data));
        // alert(JSON.stringify(reqData));
        // this.setState({
        //   userList: d.data,
        // });
      })
      .catch(() => {
        alert("error");

        // notification["error"]({
        //   message: "Error !!",
        //   description: "Something went wrong fetching user list, try again.",
        // });
      });
  };

  const showGroupMember = (id) => {
    // alert(JSON.stringify(id));
    setShowGroupMemberModal(true);

    setLoadingGroupMember(true);
    axios
      .get(`https://apitranscribe.ranuvijay.me/chat/userInGroup/${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((d) => {
        // alert(JSON.stringify(d.data));
        let dr = [];
        d.data.participants_email.forEach((dd) => dr.push({ email: dd }));
        // alert(JSON.stringify(dr))
        // setChatMembers(d.data.participants);\
        setChatMembers(dr);

        setLoadingGroupMember(false);
      })
      .catch((e) => {
        setLoadingGroupMember(false);
        setShowGroupMemberModal(false);
        notification["error"]({
          message: "Error !!",
          description: "Somwthing went wrong, try again.",
        });
      });
  };

  const createSession = (values) => {
    // alert(JSON.stringify(values));
    const name = values.sessionName;
    const group = values.chatGroup;
    // const date = moment(date2).format(format2)
    const date = moment(values.Date).format("YYYY-MM-DD");
    // const startTime = values.startTime;
    const startTime = moment(values.startTime).format("hh:mm:ss");
    // hh:mm[:ss
    const endTime = moment(values.endTime).format("hh:mm:ss");
    // const endTime = values.endTime;

    setLoadingCreateSession(true);

    axios
      .post(
        "https://apitranscribe.ranuvijay.me/chat/chatSession/",
        {
          name: name,
          group: group,
          active: true,
          date: date,
          startTime: startTime,
          endTime: endTime,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((d) => {
        notification["success"]({
          message: "Success !!",
          description: " New Session created.",
        });
        setLoadingCreateSession(false);
        setShowCreateSessionModal(false);
      })
      .catch((e) => {
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong, try again.",
        });
        setLoadingCreateSession(false);
      });
  };

  function createNewGroup(values) {
    console.log(values);
    const finalUsersInGroup = [
      ...values.selectedUser,
      loggedInUserDetail[0].id,
    ];
    const groupName = values.groupName;
    const client = loggedInUserDetail[0].client;
    // const finalUsersInGroup = [...newGroupData.users, loggedInUserDetail[0].id];
    // const groupName = newGroupData.groupName;

    axios
      .post(
        // "https://apitranscribe.ranuvijay.me/chat/",
        "https://apitranscribe.ranuvijay.me/chat/chatDetail/",
        {
          groupName: groupName,
          participants: finalUsersInGroup,
          client: client,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((r) => {
        notification["success"]({
          message: "Success !!",
          description: "New Group Created.",
        });
      })
      .catch((e) => {
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong, Try again.",
        });
      });
  }

  const getSEssion = () => {
    axios
      .get(
        `https://apitranscribe.ranuvijay.me/chat/chatSession/?user=${loggedInUserDetail[0].id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((d) => {
        setSessionToGetSessionText(d.data);
        // console.log(d.data);
      })
      .catch((e) => {
        notification["error"]({
          message: "Error !!",
          description: "SOmething went wrong.",
        });
      });
  };

  const getUserForSession = () => {
    if (selectedSessionforTranscribe) {
      axios
        .get(
          `https://apitranscribe.ranuvijay.me/chat/userInSession?session=${selectedSessionforTranscribe}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((d) => {
          setUserToGetSessionText(d.data);
        })
        .catch((e) => {
          notification["error"]({
            message: "Error !!",
            description: "SOmething went wrong.",
          });
        });
    } else {
      notification["error"]({
        message: "Error !!",
        description: "Select Session",
      });
    }
  };

  const getTRanscribedText = (values) => {
    const session = values.session;
    const participant = values.participant;
    axios
      .get(
        `https://apitranscribe.ranuvijay.me/chat/audio?session=${session}&participant=${participant}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((d) => {
        console.log(d.data);
        if (d.data.error) {
          notification["error"]({
            message: "NO data",
            description: "Found NO Data",
          });
        } else if (d.data.success) {
          Modal.info({
            title: "This is transcribed message",
            content: d.data.success,
            onOk() {},
          });
        }
      })
      .catch((e) => {
        notification["error"]({
          message: "Error !!",
          description: "SOmething went wrong.",
        });
      });
  };

  useEffect(() => {
    if (selectedSession) {
      const filteredGroup = activeSessions.filter(
        (d) => JSON.stringify(d.id) === selectedSession
      )[0].group;

      const allParticipants = groups.filter((d) => d.id === filteredGroup)[0]
        .participants;

      const l = loggedInUserDetail[0].id;
      const participants = allParticipants.filter((d) => d !== l);

      setGroupMemberInSelectedSession(participants);
    }
  }, [selectedSession]);

  const userDropDownList = userList?.map((d) => (
    <Option key={d.id}>{d.email}</Option>
  ));

  const optionGroup = groups?.map((d) => (
    <Option key={d.id}>{d.groupName}</Option>
  ));

  const sessionDropdown = sessionToGetSessionText?.map((d) => (
    <Option key={d.id}>{d.name}</Option>
  ));

  const userForSessionDropdown = userToGetSessionText?.map((d) => (
    <Option key={d.id}>{d.username}</Option>
  ));

  const DropdownItems = [
    {
      key: "234",
      label: (
        <span
          onClick={() => {
            setShowGetTRanscribedText(true);
          }}
        >
          Get Transcribed Text
        </span>
      ),
    },
    {
      label: (
        <span
          onClick={() => {
            setShowCreateSessionModal(true);
          }}
        >
          Create New Chat Session
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span
          onClick={() => {
            setShowCReateGroupMOdal(true);
          }}
        >
          Create New Group
        </span>
      ),
      key: "2",
    },
    {
      label: (
        <span
          onClick={() => {
            setShowAddUserModal(true);
          }}
        >
          Create New User
        </span>
      ),
      key: "3",
    },
    {
      key: "4",
      label: (
        <span
          onClick={() => {
            setShowGroupModal(true);
          }}
        >
          Group List
        </span>
      ),
    },
    {
      key: "5",
      label: (
        <span
          onClick={() => {
            localStorage.removeItem("token");
            const g = localStorage.getItem("token");
            if (!g) {
              setToken(null);
            }
          }}
        >
          Log Out
        </span>
      ),
    },
  ];
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log('current', current);
    // console.log(moment().endOf("day"));
    return current && current < moment().subtract(1, "days");
    // moment().endOf("day");
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const buttonayout = {
    wrapperCol: {
      xs: { span: 12, offset: 6 },
      sm: { span: 16, offset: 8 },
    },
  };

  return (
    <>
      <Layout
        style={{
          // backgroundColor: "red",
          width: "100%",
          height: "100vh",
        }}
      >
        <Sider
          breakpoint="xs"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          theme="light"
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          // style={{ backgroundColor: "orange", width:'100%' }}
        >
          <div
            //  style={{
            //   back
            // }}
            style={{
              backgroundColor: "orange",
              width: "100%",
              padding: "10px 0px",
            }}
          >
            <b>Active Sessions</b>
          </div>

          <Menu
            mode="inline"
            onClick={(e) => {
              console.log("selectedSEssion", e.key);
              setSelectedSession(e.key);
              selectedSessionInRef.current = e.key;
            }}
            items={activeSessions?.map((d) => ({
              key: d.id,
              label: d.sessionName,
              icon: <MessageTwoTone />,
            }))}
          />
        </Sider>
        <Layout>
          <Header
            className="site-layout-sub-header-background"
            style={{
              padding: 0,
              margin: 0,
              backgroundColor: "#fff",
              // backgroundColor: "green",
              height: "45px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // backgroundColor: "orange",
                // display: "flex",
                // justifyContent: "end",
                paddingRight: "14px",
                // alignItems:'center'
              }}
            >
              <Dropdown
                // overlay={DropdownItems}

                menu={{ items: DropdownItems }}
                trigger={["click"]}
              >
                <Avatar
                  // size="large"
                  src="https://joeschmoe.io/api/v1/random"
                  // icon={<UserOutlined />}
                  style={{
                    border: "1px solid #d9d9d9",
                    cursor: "pointer",
                  }}
                />
                {/* <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Click me
                    <DownOutlined />
                  </Space>
                </a> */}
              </Dropdown>
            </div>
          </Header>
          <Content
            style={
              {
                // backgroundColor: "red",
              }
            }
          >
            <div
              // className="site-layout-background"
              style={
                {
                  // padding: 24,
                  // minHeight: 360,
                  // backgroundColor: "blue",
                }
              }
            >
              <div className="bg-[yellow] w-[70%]">
                <audio controls id="music"></audio>
              </div>
              {selectedSession ? (
                <Button type="primary" onClick={startAudio}>
                  Start
                </Button>
              ) : (
                <p>
                  Create and <b>Select</b> a new session to start audio call
                </p>
              )}
            </div>
          </Content>
          <Footer
            style={{
              height: "20px",
              padding: 0,
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <small>Ranu Vijay</small>
          </Footer>
        </Layout>
      </Layout>

      {/* <div>
        <div className="flex">
          <div className="bg-[yellow] w-[70%]">
            <audio controls id="music"></audio>
            <button onClick={startAudio}>Start</button>
          </div>
        </div>
      </div> */}

      <Modal
        title="Create New Chat Session"
        open={showCreateSessionModal}
        // onOk={handleOk}
        onCancel={() => {
          setShowCreateSessionModal(false);
        }}
        footer={null}
        centered
        destroyOnClose={true}
        // loading={loadingCreateSession}
      >
        {loadingCreateSession ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Form
            {...formItemLayout}
            style={{
              padding: "30px 30px",
              border: "1px solid #d9d9d9",
            }}
            labelAlign="left"
            onFinish={createSession}
          >
            <Form.Item
              label="Session Name"
              name="sessionName"
              rules={[
                {
                  required: true,
                  message: "Input Session Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Chat Group"
              name="chatGroup"
              rules={[
                {
                  required: true,
                  message: "Select Chat Group",
                },
              ]}
            >
              <Select
                allowClear
                showSearch={true}
                defaultActiveFirstOption={false}
                // style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  !userList ? <Spin size="small" /> : "No User Found"
                }
                placeholder="select chat group"
                onFocus={getUserList}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              >
                {optionGroup}
                {/* {userDropDownList} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Date"
              name="Date"
              rules={[
                {
                  required: true,
                  message: "Input Date",
                },
              ]}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>

            <Form.Item
              label="Start Time"
              name="startTime"
              rules={[
                {
                  required: true,
                  message: "Input start time",
                },
              ]}
            >
              <TimePicker />
            </Form.Item>

            <Form.Item
              label="End Time"
              name="endTime"
              rules={[
                {
                  required: true,
                  message: "Input end time",
                },
              ]}
            >
              <TimePicker />
            </Form.Item>
            <Form.Item {...buttonayout}>
              <Button type="primary" htmlType="submit" shape="round">
                Create New Chat Session
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>

      <Modal
        title="Create New Group"
        open={showCReateGroupMOdal}
        onCancel={() => {
          setShowCReateGroupMOdal(false);
        }}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <Form
          onFinish={createNewGroup}
          style={{
            padding: "30px 30px",
            border: "1px solid #d9d9d9",
          }}
          labelAlign="left"
        >
          <Form.Item
            label="Group Name"
            name="groupName"
            rules={[
              {
                required: true,
                message: "Input Group Name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Select Users"
            name="selectedUser"
            rules={[
              {
                required: true,
                message: "Select Users",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch={true}
              defaultActiveFirstOption={false}
              // style={{ width: "100%" }}

              notFoundContent={
                !userList ? <Spin size="small" /> : "No User Found"
              }
              placeholder="select users"
              onFocus={getUserList}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {userDropDownList}
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" shape="round">
              Create a Group
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Get TRanscribed Data"
        open={showGetTRanscribedText}
        onCancel={() => {
          setShowGetTRanscribedText(false);
        }}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <Form
          onFinish={getTRanscribedText}
          style={{
            padding: "30px 30px",
            border: "1px solid #d9d9d9",
          }}
          name="sd"
          labelAlign="left"
        >
          <Form.Item
            label="Session"
            name="session"
            rules={[
              {
                required: true,
                message: "Select Session",
              },
            ]}
          >
            <Select
              allowClear
              showSearch={true}
              defaultActiveFirstOption={false}
              // style={{ width: "100%" }}

              notFoundContent={
                !sessionToGetSessionText ? (
                  <Spin size="small" />
                ) : (
                  "No User Found"
                )
              }
              onChange={(d) => {
                // console.log(d);
                setSelectedSessionforTranscribe(d);
              }}
              placeholder="select session"
              onFocus={getSEssion}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {sessionDropdown}
            </Select>
          </Form.Item>

          <Form.Item
            label="Participants"
            name="participant"
            rules={[
              {
                required: true,
                message: "Select User",
              },
            ]}
          >
            <Select
              allowClear
              showSearch={true}
              defaultActiveFirstOption={false}
              // style={{ width: "100%" }}

              notFoundContent={
                !userToGetSessionText ? <Spin size="small" /> : "No User Found"
              }
              placeholder="select session"
              onFocus={getUserForSession}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {userForSessionDropdown}
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" shape="round">
              Get Transcribed Text
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Add a User"
        open={showAddUserModal}
        // onOk={handleOk}
        okText="Create a User"
        onCancel={() => {
          setShowAddUserModal(false);
        }}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <AddUser
          setLoading={setLoading}
          title="Create a User"
          client={loggedInUserDetail && loggedInUserDetail[0].client}
        />
      </Modal>

      <Modal
        title="Group Member"
        open={showGroupMemberModal}
        // onOk={handleOk}
        // okText="Create a User"
        onCancel={() => {
          setShowGroupMemberModal(false);
          // setShowAddUserModal(false);
        }}
        footer={null}
        centered
        destroyOnClose={true}
      >
        {loadingGroupMember ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <List
            itemLayout="vertical"
            dataSource={chatMembers}
            renderItem={(item, index) => (
              <List.Item
                key={item.id}
                // extra={
                //   <Dropdown
                //     menu={{
                //       items: [
                //         { key: 1, label: "Create New Session " },
                //         {
                //           key: 2,
                //           label: (
                //             <span
                //               onClick={(v) => {
                //                 showGroupMember(item.id);
                //               }}
                //             >
                //               Group Members
                //             </span>
                //           ),
                //         },
                //       ],
                //     }}
                // trigger={["click"]}
                //   >
                //     <Button
                //       type="primary"
                //       shape="round"
                //       icon={<DownOutlined />}
                //     >
                //       More
                //     </Button>
                //   </Dropdown>
                // }
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "#595959",
                      }}
                    >
                      {index + 1}
                    </Avatar>
                  }
                  title={item.email}
                />
              </List.Item>
            )}
          />
        )}
      </Modal>

      <Modal
        title="Groups"
        open={showGroupModal}
        okText="Group List"
        onCancel={() => {
          // setShowAddUserModal(false);
          setShowGroupModal(false);
        }}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <List
          itemLayout="vertical"
          dataSource={groups}
          renderItem={(item, index) => (
            <List.Item
              key={item.id}
              extra={
                <Dropdown
                  menu={{
                    items: [
                      { key: 1, label: "Create New Session " },
                      {
                        key: 2,
                        label: (
                          <span
                            onClick={(v) => {
                              showGroupMember(item.id);
                            }}
                          >
                            Group Members
                          </span>
                        ),
                      },
                    ],
                  }}
                  trigger={["click"]}
                >
                  <Button type="primary" shape="round" icon={<DownOutlined />}>
                    More
                  </Button>
                </Dropdown>
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: "#595959",
                    }}
                  >
                    {index + 1}
                  </Avatar>
                }
                title={item.groupName}
              />
            </List.Item>
          )}
        />
      </Modal>
      {/* </div> */}
    </>
  );
}

export default Application;
