import axios from "axios";
import { useNavigate } from "react-router-dom";
import PublicMenu from "./publicMenu";
import { Button, Form, Input, notification } from "antd";

export default function Login() {
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values);

    const email = values.email;
    const pass = values.password;

    axios
      .post("https://apitranscribe.ranuvijay.me/dj-rest-auth/login/", {
        username: email,
        // email: email,
        password: pass,
      })
      .then((res) => {
        // alert(JSON.stringify(res));
        const token = res.data.key;
        localStorage.setItem("token", token);
        // setIsAuthenticated(true);
        navigate("/app");
        console.log("loggedIn");
        // axios
        //   .get("https://apitranscribe.ranuvijay.me/dj-rest-auth/user/", {
        //     headers: {
        //       Authorization: `Token ${token}`,
        //     },
        //   })
        //   .then((d) => {
        //     dispatch(userData(d.data));
        //   })
        //   .catch((e) => {
        //     alert("error");
        //   });
      })
      .catch((err) => {
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong, try again.",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <>
      <PublicMenu />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "93vh",
        }}
      >
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{
            padding: "70px 50px",
            border: "1px solid #d9d9d9",
          }}
          // autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="email"
            rules={[
              {
                required: true,
                message: "Input your username !",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Input password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" shape="round">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>

      {/* <div className="flex justify-center items-center width-[100%] h-screen">
        <form
          className="bg-slate-300 px-10 py-6 space-x-4"
          onSubmit={(e) => {
            onsubmit(e);
          }}
        >
          <input
            className="border-solid"
            type="email"
            placeholder="email"
            onChange={(v) => {
              console.log(v);
              setAuthData({ ...authData, email: v.target.value });
            }}
          ></input>
          <input
            type="password"
            placeholder="password"
            onChange={(v) => {
              setAuthData({ ...authData, pass: v.target.value });
            }}
          ></input>
          <button
            className="px-6 py-2 bg bg-slate-500 text-[#fff]"
            type="submit"
          >
            Login
          </button>
        </form>
      </div> */}
    </>
  );
}
