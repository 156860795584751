// import './App.css';
// import 'antd/dist/antd.css';
import "antd/dist/antd.compact.css";
import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./publicComponent/home";
import Login from "./publicComponent/login";
import Register from "./publicComponent/register";
import Application from "./main/application";
import AddUser from "./main/addUser";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/app");
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/app" element={<Application />} />
    </Routes>
    // <>
    // {/* asdasdasd */}
    // {/* <Route path="/addUser" element={<AddUser />} /> */}
    // </>
  );
}

export default App;
